import React from "react";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import HeroSection from "../components/HeroSection";
import Section from "../components/Section";
import stockperksImg from "../images/stockperks.png";

const TermsAndConditions = () => {
  require("./terms.css");
  return (
    <Layout>
      <SEO
        title="Terms of Service"
        url={"/terms/"}
        image={stockperksImg}
        description={"Terms of Use, Stockperks, LLC."}
      />
      <HeroSection>
        <h1>Stockperks, LLC Terms of Service</h1>
        <h4>Date Last Revised: October 1, 2023</h4>
      </HeroSection>
      <Section isTextCentered={false} style={{paddingLeft: 15, paddingRight: 15}}>
        <p className="c5">
          <span className="c1">These Terms of Service (</span>
          <span className="c4">&ldquo;Terms&rdquo;</span>
          <span className="c1">
            ) explain the contractual relationship between you and Stockperks,
            LLC (
          </span>
          <span className="c4">
            &ldquo;Stockperks,&rdquo; &ldquo;us,&rdquo; our&rdquo; or
            &ldquo;we&rdquo;
          </span>
          <span className="c1">
            ), regarding your use of, and access to, our website located at{" "}
          </span>
          <span className="c14 c1 c22">www.stockperks.com</span>
          <span className="c1">&nbsp;(</span>
          <span className="c4">&ldquo;Website&rdquo;</span>
          <span className="c1">), our mobile application (&ldquo;</span>
          <span className="c4">App</span>
          <span className="c1">
            &rdquo;), and the features and functionality made available to you
            through the Website and App (together with the Website and App, the
            &ldquo;
          </span>
          <span className="c4">Service</span>
          <span className="c1">&rdquo;). These Terms, which include our </span>
          <span className="c14 c1">
            <a className="c8" href={"/privacy"}>
              Privacy Policy
            </a>
          </span>
          <span className="c1">
            , govern access to and use of the Service and{" "}
          </span>
          <span className="c1">Stockperks Content</span>
          <span className="c1">
            &nbsp;(as defined below) by each user who accesses or uses the
            Service (</span><span className="c4">&ldquo;you&rdquo;</span>
          <span>), and constitute a binding legal agreement between
            you and Stockperks. If you have questions regarding the Terms or
            wish to obtain additional information, please send an e-mail to{" "}
          </span>{" "}
          hello@stockperks.com.
        </p>

        <p className="c3">
          <span className="c7 c4"></span>
        </p>
        <p className="c5">
          <span className="c4">
            YOU ACKNOWLEDGE AND AGREE THAT BY ACCESSING OR USING THE SERVICE, OR
            BY DOWNLOADING OR POSTING ANY CONTENT FROM OR THROUGH THE
            &nbsp;SERVICE, YOU ARE INDICATING THAT YOU HAVE READ, UNDERSTAND AND
            AGREE TO BE BOUND BY THESE TERMS.
          </span>
        </p>

        <p className="c3">
          <span className="c0"></span>
        </p>
        <p className="c5">
          <span className="c4 c14">IMPORTANT</span>
          <span className="c7 c4">
            : THESE TERMS CONTAIN PROVISIONS THAT GOVERN THE RESOLUTION OF
            CLAIMS BETWEEN STOCKPERKS AND YOU, INCLUDING THE REQUIREMENT THAT
            YOU RESOLVE ANY DISPUTES YOU HAVE WITH STOCKPERKS THROUGH FINAL AND
            BINDING ARBITRATION. PLEASE SEE THE DISPUTES CLAUSE IN SECTION 21
            BELOW FOR MORE INFORMATION.
          </span>
        </p>

        <p className="c3">
          <span className="c0"></span>
        </p>
        <p className="c5">
          <span className="c7 c4">
            STOCKPERKS IS NOT ENGAGED IN RENDERING FINANCIAL OR INVESTMENT
            ADVICE OR SERVICES VIA THE SERVICE. Stockperks makes no representation, warranty or endorsement regarding any Company featured or, or with which you interact, through the Service. You should be careful and exercise caution in entering into financial or investment transactions. No agency, partnership, joint venture or other form of joint enterprise or employment relationship exists between Stockperks and any Companies, including Companies who offer Perks through the Service.

          </span>
        </p>

        <p className="c3">
          <span className="c0"></span>
        </p>
        <p className="c5">
          <span className="c0">
            You acknowledge and agree that you have the legal power and
            authority to enter into these Terms. If you are entering into the
            Terms on behalf of a company or other entity, you represent that you
            have the authority to bind such entity to the Terms, in which case
            the terms &quot;you&quot; or &quot;your&quot; refers to such entity.
          </span>
        </p>

        <p className="c3">
          <span className="c0"></span>
        </p>
        <ol className="c9 lst-kix_list_5-0 start" start="1">
          <li className="c2">
            <span className="c7 c4">License</span>
          </li>
        </ol>
        <p className="c3">
          <span className="c0"></span>
        </p>
        <p className="c5">
          <span className="c1">
            Subject to your compliance with these Terms, you are granted a limited, non-exclusive, non-transferable license to access and use the Service on a computer, tablet device or mobile phone that you own or lawfully control.
            &nbsp;If you have accessed or downloaded
            the App from any &ldquo;app&rdquo; store or distribution platform,
            such as the Apple App Store or Google Play (&ldquo;
          </span>
          <span className="c4">App Provider</span>
          <span className="c0">
            &rdquo;), you acknowledge and agree that: (i) these Terms and the
            license granted herein are between you and us only, and we are
            solely responsible for the App; (ii) the App Provider has no
            obligation to furnish any maintenance and support services for the
            App; (iii) the App Provider is not responsible for addressing any
            claims you have or any claims of any third party relating to the App
            or your possession and use of the App; (iv) the App Provider, and
            its subsidiaries, are third party beneficiaries of these Terms as
            related to your license of the App, and that the App Provider will
            have the right (and will be deemed to have accepted the right) to
            enforce these Terms as related to your license of the App against
            you as a third-party beneficiary thereof; and (v) you will comply
            with all applicable third-party terms of service when using the App.
          </span>
        </p>

        <p className="c3 c15">
          <span className="c0"></span>
        </p>
        <ol className="c9 lst-kix_list_5-0" start="2">
          <li className="c2">
            <span className="c7 c4">Perks</span>
          </li>
        </ol>
        <p className="c3">
          <span className="c0"></span>
        </p>
        <p className="c5">
          <span className="c1 c6">For Companies</span>
          <span className="c1 c6">.</span>
          <span className="c1">
            &nbsp;The Service enables participating companies (&ldquo;
          </span>
          <span className="c4">Company(ies)</span>
          <span className="c1">
            &rdquo;) to administer certain loyalty programs, benefits,
            incentives, privileges, or perks offered by the Company to the
            Company&rsquo;s shareholders (&ldquo;
          </span>
          <span className="c4">Perks</span>
          <span className="c1">
            &rdquo;). Using the Service, Companies can (i) offer Perks{" "}
          </span>
          <span className="c1">of the frequency and type</span>
          <span className="c1">
            &nbsp;that they determine and utilize Perks for new product or
            service launches or campaigns; (ii) receive regular data made
            available regarding investor sentiment on the overall stock market
            and investing (&ldquo;
          </span>
          <span className="c4">Market Sentiment Reports</span>
          <span className="c1">
            &rdquo;); (iii) create and circulate surveys to the users of the
            Service to gather feedback from investors on various topics,
            including factors that would lead investors to invest more, views on
            dividend policies, etc. (&ldquo;
          </span>
          <span className="c4">Surveys</span>
          <span className="c1">
            &rdquo;); and (iv) communicate with shareholders directly through
            the messaging feature of the Service on topics including quarterly
            earning recaps, proxy vote reminders, key messages from Company
            management, feedback, and respond to inquiries from shareholders.
          </span>
        </p>
        <p className="c3">
          <span className="c0 c11"></span>
        </p>
        <p className="c5">
          <span className="c1 c6">For Shareholders</span>
          <span className="c1">
            . The Service also enables you, as a shareholder of a Company, the
            ability to (i) browse and apply to receive Perks of the Companies in
            which you invest; (ii) connect and communicate directly with
            Companies by responding to Surveys or by communicating directly with
            the Company through the messaging feature of the Service to give
            your opinion, feedback, and
          </span>
          <span className="c1"> ask questions</span>
          <span className="c0">
            ; (iii) be a part of a stronger shareholder and investor community
            and learn from and engage with fellow shareholders and share Perks
            with others who may have an interest; and (iv) access weekly Market
            Sentiment Reports.
          </span>
        </p>
        <p className="c3">
          <span className="c0"></span>
        </p>
        <p className="c5">
          <span className="c1">
            Perks are offered by, managed and controlled by the Company and your
            qualification for and redemption of any Perk is subject to the
            applicable Company&rsquo;s terms and conditions related thereto.
            Qualifications to receive any particular Perk are subject to review
            and determination by the Company, not by Stockperks. By using the
            Service, you acknowledge the
          </span>
          <span className="c1"> Company&rsquo;s authority</span>
          <span className="c1">
            &nbsp;to (i) determine eligibility criterion of Perks; (ii) award or
            refuse to award Perks based on the Company&rsquo;s evaluation of
            your eligibility for the Perk; (iii) add or remove Perks; (iv)
            discontinue Perks; and (v){" "}
          </span>
          <span className="c1">view </span>
          <span className="c1">
            that portion of your stock portfolio related to the Company to
            assess your qualifications and eligibility for the applicable Perk.
            Stockperks is not responsible for your failure to qualify for,
            access or receive any Perks.
          </span>
        </p>
        <p className="c3">
          <span className="c0"></span>
        </p>
        <p className="c5">
          <span className="c0">
            Company content made available to you through the Service, including
            without limitation information about Perks and Company surveys are
            Third-Party Content as defined in Section 12 of these Terms. When
            you navigate to the Company Website or App to learn more about the
            applicable Perk, apply for or redeem Perks, you are subject to the
            Company&rsquo;s terms of use and privacy policy governing the use of
            the Company&rsquo;s Services.
          </span>
        </p>
        <p className="c3">
          <span className="c4 c7"></span>
        </p>

        <ol className="c9 lst-kix_list_5-0" start="3">
          <li className="c2">
            <span className="c7 c4">Ownership </span>
          </li>
        </ol>
        <p className="c3">
          <span className="c0"></span>
        </p>
        <p className="c5">
          <span className="c1">
            All materials contained on, in, or available through the Service,
            including all information, data, text, photographs, images,
            graphics, logos, button icons, sound, music, audio clips, data,
            forms, graphs, videos, typefaces, and other material, and software
            the selection and arrangement thereof, and all source code, software
            compilations, and other materials, but excluding Third-Party Content
            incorporated in or made available through the Service is
          </span>
          <span className="c4"> &ldquo;Our Content&rdquo;</span>
          <span className="c0">
            . Our Content is protected by copyright, trademark, patent and other
            intellectual property laws and may not be copied or imitated in
            whole or in part by you, unless as explicitly stated under these
            Terms. All copyrights, trademarks, service marks, trade dress,
            patent and other intellectual property rights, and all derivative
            works thereof associated with Our Content, whether registered or
            not, are our sole property or the property of third parties. Our
            Content includes the STOCKPERKS name and logo and other related
            names, design marks, product names, feature names and related logos
            which may not be used, copied or imitated, in whole or in part,
            without the express prior written permission of Stockperks. In
            addition, the look and feel of the Service (including all page
            headers, custom graphics, button icons, and scripts) constitutes the
            service mark, trademark and/or trade dress of Stockperks and may not
            be copied imitated or used, in whole or in part, without the express
            prior written permission of Stockperks. Our Content may also be
            protected as a collective work or compilation under U.S. copyright
            and other laws and treaties. You agree to abide by all applicable
            copyright and other laws. We do not convey, through allowing access
            to the Service, any ownership rights in Our Content. These Terms
            will govern any upgrades provided by us that replace and/or
            supplement the original Service or components thereof, unless such
            upgrade is accompanied by separate terms in which case such updated
            terms will govern.
          </span>
        </p>
        <p className="c3 c15">
          <span className="c0"></span>
        </p>
        <ol className="c9 lst-kix_list_5-0" start="4">
          <li className="c2">
            <span className="c7 c4">Use Limitations</span>
          </li>
        </ol>
        <p className="c3">
          <span className="c0"></span>
        </p>
        <p className="c5">
          <span className="c0">
            You may not create, develop, license, install, use, or deploy any
            third-party software or services to circumvent, enable, modify or
            provide access, permissions or rights to work around any technical
            limitations in the Service. You may not copy (except as expressly
            permitted by these Terms) or publish the Service, or components
            thereof, for others to copy, decompile, reverse engineer,
            disassemble, attempt to derive the source code of, or modify, or
            create derivative works of the Service, or components thereof,
            &nbsp;or any updates, or part thereof (except as and only to the
            extent any foregoing restriction is prohibited by applicable law or
            to the extent as may be permitted by the licensing terms governing
            use of any open source components included with the Service, if
            any). Any attempt to do so is a violation of our rights. If you
            breach this restriction, you may be subject to prosecution and
            damages. You may not use the Service in any manner not specifically
            authorized hereunder or in any way that is against any applicable
            laws or regulations. You understand and acknowledge that
            unauthorized disclosure, use or copying of the proprietary products
            and services provided pursuant to these Terms may cause Stockperks
            and its licensors irreparable injury, which may not be remedied at
            law, and you agree that Stockperks and its licensors&#39; remedies
            for breach of these Terms may be in equity by way of injunctive or
            other equitable relief.
          </span>
        </p>
        <p className="c3 c15">
          <span className="c10 c1"></span>
        </p>
        <ol className="c9 lst-kix_list_5-0" start="5">
          <li className="c2">
            <span className="c7 c4">Account Access and Data Processing </span>
          </li>
        </ol>
        <p className="c3">
          <span className="c0"></span>
        </p>
        <p className="c5">
          <span className="c1">
            There is no charge to access and browse the Service. You are
            responsible for any applicable Internet or telecommunication use
            charges that may apply at rates that are determined by the providers
            of such services. In order to access and browse the Service, you
            must have a compatible computer, tablet device or mobile telephone,
            internet access, mobile network access, and an Android or iOS
            operating system. The software for the Service may be upgraded from
            time to time to add support for new functions and services.
          </span>
        </p>
        <p className="c3">
          <span className="c0"></span>
        </p>
        <p className="c5">
          <span className="c1">
            When setting up your Stockperks user account (the &ldquo;
          </span>
          <span className="c4">Account</span>
          <span className="c1">
            &rdquo;), you will be prompted to link your Stockperks user profile
            with your brokerage account.{" "}
          </span>
          <span className="c1">Stockperks is committed to safeguarding your data and maintaining transparency. Here are details regarding the processing of your brokerage account data:</span>
        </p>

        <p className="c5 c20">
          <span className="c4">Types of Data: </span>
          <span className="c0">
             Stockperks will only access and process brokerage account data relevant to determining Perk eligibility and delivering the Service. This includes portfolio and transaction history data.
          </span>
        </p>

        <p className="c5 c20">
          <span className="c4">Purpose: </span>
          <span className="c0">
            The primary purpose of data processing is to identify the Companies in which you own stock in order to display Perks for which you might be eligible and so the relevant Companies can verify your ownership of such stock.
          </span>
        </p>

        <p className="c5 c20">
          <span className="c4">Storage Duration: </span>
          <span className="c0">
            Data is stored as long as your brokerage account remains connected to your Account. You can disconnect your brokerage account from within the mobile app, at which point the Data will be promptly deleted.
          </span>
        </p>

        <p className="c5 c20">
          <span className="c4">Security Measures: </span>
          <span className="c0">
             Stockperks employs robust security measures to protect your data from unauthorized access or disclosure.
          </span>
        </p>

        <p className="c5">
          <span className="c0">
            By using Stockperks, you acknowledge and agree to the outlined data processing practices.
          </span>
        </p>

        <p className="c3">
          <span className="c0"></span>
        </p>

        <ol className="c9 lst-kix_list_5-0" start="6">
          <li className="c2">
            <span className="c7 c4">Account Credentials</span>
          </li>
        </ol>
        <p className="c3">
          <span className="c0"></span>
        </p>
        <p className="c5">
          <span className="c1">
            You are responsible for safeguarding the username(s) and password(s)
            that you use to access your Account (collectively, &ldquo;
          </span>
          <span className="c4">Credentials</span>
          <span className="c1">
            &rdquo;) &nbsp;and for any activities or actions that take place
            through your Account. Please create and use strong passwords to
            access your Account.
          </span>
        </p>
        <p className="c3">
          <span className="c0"></span>
        </p>
        <p className="c5">
          <span className="c0">
            You agree to promptly notify Stockperks if you become aware of, or
            suspect, any unauthorized use of your Credentials or Account, a
            breach of security, or unauthorized copying or distribution of Our
            Content. You verify that the information you have entered is
            accurate and true. You must update your profile information in your
            Account to ensure your personal information remains up to date and
            accurate.
          </span>
        </p>
        <p className="c3">
          <span className="c7 c4"></span>
        </p>
        <ol className="c9 lst-kix_list_5-0" start="7">
          <li className="c2">
            <span className="c7 c4">Rights Concerning Your Information</span>
          </li>
        </ol>
        <p className="c3">
          <span className="c10 c1"></span>
        </p>
        <p className="c5">
          <span className="c1">You own the </span>
          <span className="c1">content </span>
          <span className="c1">
            you submit or otherwise make available to us through the Service
            (&ldquo;
          </span>
          <span className="c4">Your Content</span>
          <span className="c1">
            &rdquo;). You can control how Your Content is shared through the
            Service and you may delete or request its deletion at any time,
            unless you have shared Your Content with others and they have not
            deleted it, or it was copied or stored by other users. In order to
            enable Stockperks to operate the Service, Stockperks must obtain
            from you certain licenses and other rights to Your Content so that
            Stockperks&rsquo; use of Your Content in connection with the Service
            does not infringe copyright and other laws. Accordingly, by using
            the Service and posting or otherwise submitting or sharing Your
            Content, you grant Stockperks a nonexclusive right and license to
            copy, prepare derivative works of, improve, display, distribute,
            publish, remove, retain, add, process, analyze, and use Your Content
            in connection with the Service, all in accordance with these Terms
            and the terms of our
          </span>
          <span className="c14 c1">
            <a className="c8" href={"/privacy"}>
              {" "}
              Privacy Policy
            </a>
          </span>
          <span className="c0">
            . You agree that the foregoing rights and licenses are royalty free,
            irrevocable and worldwide, and include a right for Stockperks to
            make Your Content available to, and pass these rights along to,
            others with whom Stockperks has contractual relationships related to
            the provision of the Service or operation and improvement of the
            Service or components thereof. The foregoing rights and licenses
            terminate when you delete Your Content from the Service, or when you
            delete your account, unless Your Content has been shared with
            others, and they have not deleted it. You represent and warrant that
            you have all of the necessary rights and permissions to post Your
            Content to the Service and that you will not post content or take
            any action on the Service that infringes or violates someone
            else&#39;s rights (including, without limitation, any third party
            intellectual property, privacy or other rights) or otherwise
            violates the law.
          </span>
        </p>
        <p className="c3">
          <span className="c0"></span>
        </p>
        <ol className="c9 lst-kix_list_5-0" start="8">
          <li className="c2">
            <span className="c7 c4">User Feedback</span>
          </li>
        </ol>
        <p className="c3">
          <span className="c10 c1"></span>
        </p>
        <p className="c5">
          <span className="c1">
            All feedback, suggestions, ideas, and other submissions disclosed to
            Stockperks in connection with your use of the Service (collectively,
            &quot;
          </span>
          <span className="c4">Feedback</span>
          <span className="c0">
            &quot;) will be Stockperks&#39; property. Such disclosure of
            Feedback will constitute an assignment to Stockperks of all
            worldwide rights, titles, and interests in all copyrights and other
            intellectual property in the Feedback. Stockperks will be under no
            obligation to maintain any Feedback in confidence, pay any
            compensation for any Feedback, or respond to any Feedback.
          </span>
        </p>
        <p className="c3">
          <span className="c0"></span>
        </p>
        <ol className="c9 lst-kix_list_5-0" start="9">
          <li className="c2">
            <span className="c7 c4">Restrictions</span>
          </li>
        </ol>
        <p className="c3">
          <span className="c10 c1"></span>
        </p>
        <p className="c5">
          <span className="c0">
            You acknowledge and agree that, except as otherwise authorized by
            Stockperks in writing, you will not: a. license, sublicense, sell,
            resell, rent, lease, transfer, assign, distribute, grant a security
            interest in, or otherwise transfer any rights to, or commercially
            exploit, the Service or Our Content; b. copy, reproduce, republish,
            upload, post, transmit or distribute the Service or Our Content; c.
            modify, translate, alter, adapt, decompile, disassemble (except to
            the extent applicable laws specifically prohibit such restriction),
            reproduce, distribute or display, or create derivative works,
            compilations or collective works based on the Service or Our
            Content; d. knowingly or negligently permit other individuals or
            entities to use or copy the Service or &quot;frame&quot; or
            &quot;mirror&quot; the Service on any other server or wireless or
            Internet-based device; e. access the Service to (i) build a
            competitive product or service, (ii) build a product using similar
            ideas, features, functions or graphics of the Service, (iii) copy
            any ideas, features, functions or graphics of the Service, (iv)
            monitor its availability, performance or functionality, (v)
            undertake any endeavors aimed at deriving revenue, except as
            expressly permitted herein; or (vi) for any other benchmarking or
            competitive purposes; f. attempt to use or gain unauthorized access
            to data, accounts, hosts, systems or networks of Stockperks or its
            customers or suppliers, or those of any other party; g. breach the
            security of another user or system, or attempt to circumvent the
            user authentication or security of any host, network, or account,
            including, without limitation, accessing data not intended for you
            or logging into or making use of a server or account you are not
            expressly authorized to access; h. attempt to probe, scan or test
            the vulnerability of a system, account or network of Stockperks or
            its customers or suppliers, or any Stockperks product or service; i.
            interfere, or any attempt to interfere, with service to any user,
            host or network including, without limitation, mailbombing,
            flooding, and attempting to deliberately overload the system or
            distribute programs that &ldquo;crack,&rdquo; or make unauthorized
            changes to, software; j. forge any TCP-IP packet header or any part
            of any header information, falsify, alter or remove address
            information or other modification of e-mail headers; collect
            responses from unsolicited bulk messages, falsify references to
            Stockperks or the Service, by name or other identifier, in messages;
            impersonate any person or entity, engage in sender address
            falsification, forge anyone else&#39;s digital or manual signature,
            or perform any other similar fraudulent activity; k. restrict,
            inhibit, or otherwise interfere with the ability of any other
            person, regardless of intent, purpose or knowledge, to use or enjoy
            the Service (except for tools for safety and security functions),
            including, without limitation, posting or transmitting any
            information or software which contains a worm, virus, or other
            harmful feature, or generating levels of traffic sufficient to
            impede others&#39; ability to use, send, or retrieve information; l.
            restrict, inhibit, interfere with, or otherwise disrupt or cause a
            performance degradation, to the Service or any Stockperks (or
            Stockperks supplier) host, server, backbone network, node or
            service, or otherwise cause a performance degradation to the Service
            or to any Stockperks (or Stockperks supplier) facilities used to
            deliver the Service; m. create or use any program, tags, markers,
            bots, mousetraps, highjackers or other similar computer routines or
            sub-routines to automatically access or manipulate the Service; and
            n. knowingly: (i) send spam or otherwise duplicative or unsolicited
            messages in violation if applicable laws; or (ii) send or store
            infringing, obscene, threatening, libelous, or otherwise unlawful or
            tortious material, including material harmful to children or
            violative of third party privacy rights.
          </span>
        </p>
        <p className="c3">
          <span className="c0"></span>
        </p>
        <p className="c5">
          <span className="c0">
            Stockperks will cooperate with law enforcement or a court order
            requesting or directing Stockperks to disclose the identity of
            anyone posting any information or material prohibited by these
            Terms. Stockperks may also disclose such information if such
            disclosure is reasonably necessary to protect the rights, property,
            or personal safety of Stockperks, its clients, or the public.
          </span>
        </p>
        <p className="c3">
          <span className="c0"></span>
        </p>
        <ol className="c9 lst-kix_list_5-0" start="10">
          <li className="c19 c15">
            <span className="c7 c4">Jurisdiction</span>
          </li>
        </ol>
        <p className="c3">
          <span className="c0"></span>
        </p>
        <p className="c5">
          <span className="c0">
            This service is intended for users worldwide, including the United States, the European Union (EU), the United Kingdom (UK),
            Switzerland, and other global regions. By using the Service, you agree to submit to the jurisdiction of the
            United States for any legal matters that may arise, including disputes or claims. For more information on
            your rights, please refer to our </span>
            <span className="c14 c1">
              <a className="c8" href={"/privacy"}>
                Privacy Policy
              </a>
            </span>
        </p>
        <p className="c3">
          <span className="c10 c1"></span>
        </p>
        <ol className="c9 lst-kix_list_5-0" start="11">
          <li className="c2">
            <span className="c7 c4">Termination, Suspension of Service</span>
          </li>
        </ol>
        <p className="c3">
          <span className="c10 c1"></span>
        </p>
        <p className="c5">
          <span className="c0">
            Stockperks may terminate your password, account or use of the
            Service if you breach or otherwise fail to comply with these Terms
            or Stockperks&rsquo; payment policies. In addition, Stockperks may
            terminate your access to the Service at any time in its sole
            discretion. If access to or use of the Service is suspended, whether
            for non-payment or any other reason, in order to reinstate service,
            you must re-subscribe.
          </span>
        </p>
        <p className="c3">
          <span className="c0"></span>
        </p>
        <ol className="c9 lst-kix_list_5-0" start="12">
          <li className="c15 c19">
            <span className="c7 c4">Third Party Content</span>
          </li>
        </ol>
        <p className="c3">
          <span className="c0"></span>
        </p>
        <p className="c5">
          <span className="c1">
            The Service (i) contains links to other websites, including without
            limitation, Company websites, which are owned and operated by
            third-parties; (ii) pulls data and information from websites and
            services which are owned and operated by third-parties to aggregate
            and present information to you and the Companies for the purposes
            described on the Website, including without limitation to provide
            Market Sentiment Reports, and (iii) contains content such as
            graphics and photos, that may be owned by third parties and may be
            of interest to our users (collectively, &ldquo;
          </span>
          <span className="c4">Third-Party Content</span>
          <span className="c0">&rdquo;). </span>
        </p>
        <p className="c3">
          <span className="c0"></span>
        </p>
        <p className="c5">
          <span className="c0">
            WE DO NOT INVESTIGATE OR MONITOR THIRD-PARTY CONTENT, NOR DO WE HAVE
            CONTROL OVER THE TIMING OR AVAILABILITY OF SUCH THIRD-PARTY CONTENT,
            AND THEREFORE WE ARE NOT RESPONSIBLE FOR AND EXPLICITLY DISCLAIM ANY
            AND ALL LIABILITY FOR ALL THIRD-PARTY CONTENT.
          </span>
        </p>
        <p className="c3">
          <span className="c0"></span>
        </p>
        <p className="c5">
          <span className="c0">
            When you use a link to go from our Service to a third-party website,
            these Terms and our Privacy Policy are no longer in effect. We
            encourage you to read and consider the terms and conditions, privacy
            policies and other policies of these other websites and services
            before using them.
          </span>
        </p>
        <p className="c3">
          <span className="c0"></span>
        </p>

        <p className="c3">
          <span className="c17 c14 c4"></span>
        </p>
        <ol className="c9 lst-kix_list_5-0" start="13">
          <li className="c2">
            <span className="c7 c4">Copyright Infringement</span>
          </li>
        </ol>
        <p className="c3">
          <span className="c0"></span>
        </p>
        <p className="c5">
          <span className="c0">
            We respect the intellectual property rights of others, and require
            that people who use the Service do the same. &nbsp;It is our policy
            to respond to notices of alleged copyright infringement that comply
            with the Digital Millennium Copyright Act. &nbsp;For more
            information,
          </span>
          <span className="c0"> please see</span>
          <span className="c0">&nbsp;our </span>
          <span className="c17 c14 c1">DMCA Policy</span>
          <span className="c0">.</span>
        </p>
        <p className="c3">
          <span className="c7 c4"></span>
        </p>
        <ol className="c9 lst-kix_list_5-0" start="14">
          <li className="c2">
            <span className="c7 c4">Indemnification </span>
          </li>
        </ol>
        <p className="c3">
          <span className="c0"></span>
        </p>
        <p className="c5">
          <span className="c1">
            By using the Service, you agree to defend, indemnify and hold us,
            our affiliates, partners, App Providers, officers, members,
            directors and employees harmless from any and all claims,
            liabilities, costs and expenses, including reasonable
            attorneys&rsquo; fees, arising in any way from your use of the
            Service or the creation, placement or transmission of any message,
            information, software or other materials through the Service by you
            or related to any violation of these Terms by you (collectively,
            &ldquo;
          </span>
          <span className="c4">Indemnified Matters</span>
          <span className="c0">
            &rdquo;). We shall control the defense of any Indemnified Matters
            through counsel of our choice.
          </span>
        </p>
        <p className="c3">
          <span className="c0"></span>
        </p>
        <ol className="c9 lst-kix_list_5-0" start="15">
          <li className="c2">
            <span className="c7 c4">Service Interruptions</span>
          </li>
        </ol>
        <p className="c3">
          <span className="c0"></span>
        </p>
        <p className="c5">
          <span className="c0">
            It may be necessary for Stockperks to perform scheduled or
            unscheduled repairs, maintenance, or upgrades and such activities
            may temporarily degrade the quality of the Service or result in a
            partial or complete outage of the Service. Stockperks provides no
            assurance that you will receive advance notification of such
            activities or that the Service will be uninterrupted or error-free.
            Any degradation or interruption of the Service will not give rise to
            a refund or credit of any fees paid by you.
          </span>
        </p>
        <p className="c3">
          <span className="c0"></span>
        </p>
        <ol className="c9 lst-kix_list_5-0" start="16">
          <li className="c2">
            <span className="c7 c4">Disclaimer of Warranties</span>
          </li>
        </ol>
        <p className="c21">
          <span className="c1">
            THE SERVICE IS PROVIDED &ldquo;AS IS&rdquo; AND &ldquo;AS
            AVAILABLE,&rdquo; WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR
            IMPLIED. WITHOUT LIMITATION OF THE FOREGOING, WE SPECIFICALLY
            DISCLAIM, FOR THE AVOIDANCE OF DOUBT (ON OUR BEHALF, OUR
            SUBSIDIARIES, APP PROVIDERS AND/OR OUR AFFILIATES) ANY AND ALL
            WARRANTIES, INCLUDING, BUT NOT LIMITED TO, ANY WARRANTIES: (i)
            CONCERNING THE AVAILABILITY, ACCURACY, RELIABILITY, SECURITY,
            TIMELINESS, QUALITY, USEFULNESS, INTEROPERABILITY OR CONTENT OF THE
            SERVICE; (ii) OF TITLE, WARRANTY OF NON-INFRINGEMENT, WARRANTIES OF
            MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE REGARDING THE
            SERVICE OR OUR CONTENT; AND (iii) REGARDING THE PROVISION OF THE
            SERVICE. THIS DISCLAIMER OF LIABILITY APPLIES TO ANY DAMAGES OR
            INJURY CAUSED BY THE SERVICE, INCLUDING WITHOUT LIMITATION AS A
            RESULT OF ANY FAILURE OF PERFORMANCE, ERROR, OMISSION, INTERRUPTION,
            DELETION, DEFECT, DELAY IN OPERATION OR TRANSMISSION, COMPUTER
            VIRUS, COMMUNICATION LINE FAILURE, THEFT OR DESTRUCTION OR
            UNAUTHORIZED ACCESS TO, ALTERATION OF, OR USE OF RECORD, WHETHER FOR
            BREACH OF CONTRACT, TORT, NEGLIGENCE, OR UNDER ANY OTHER CAUSE OF
            ACTION. WE DO NOT WARRANT OR GUARANTEE THAT THE SERVICE WILL BE
            UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE OR THAT DEFECTS IN THE
            SERVICE WILL BE CORRECTED. YOU ASSUME THE ENTIRE RISK AS TO THE
            RESULTS AND PERFORMANCE OF THE SERVICE.
          </span>
        </p>

        <ol className="c9 lst-kix_list_5-0" start="17">
          <li className="c2">
            <span className="c7 c4">Limitation of Liability</span>
          </li>
        </ol>
        <p className="c21">
          <span className="c1">
            IN NO EVENT WILL STOCKPERKS OR ITS OFFICERS, MEMBERS, DIRECTORS,
            EMPLOYEES, PARENTS, AFFILIATES, SUCCESSORS OR ASSIGNS, BE LIABLE TO
            ANY PARTY (i) FOR ANY INDIRECT, SPECIAL, PUNITIVE, INCIDENTAL OR
            CONSEQUENTIAL DAMAGES (INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR
            LOSS OF BUSINESS PROFITS, LOSS OF INFORMATION, LOSS OF PROFITS AND
            THE LIKE), OR ANY OTHER DAMAGES ARISING IN ANY WAY OUT OF THE
            AVAILABILITY, USE, RELIANCE ON, OR INABILITY TO USE THE SERVICE OR
            OUR CONTENT EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH
            DAMAGES, AND REGARDLESS OF THE FORM OF ACTION, WHETHER IN CONTRACT,
            TORT, OR OTHERWISE; OR (ii) FOR ANY CLAIM ATTRIBUTABLE TO ERRORS,
            OMISSIONS, OR OTHER INACCURACIES IN, OR DESTRUCTIVE PROPERTIES OF
            ANY OTHER SOFTWARE OR OTHER CONTENT INCLUDED AS PART OF THE SERVICE.
            GIVEN THAT SOME STATES OR JURISDICTIONS DO NOT ALLOW THE EXCLUSION
            OR THE LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL
            DAMAGES, IN SUCH STATES OR JURISDICTIONS, OUR LIABILITY (INCLUDING
            OUR OFFICERS, MEMBERS, DIRECTORS, EMPLOYEES, PARENTS, AND
            AFFILIATES) SHALL BE LIMITED TO THE FULLEST EXTENT PERMITTED BY LAW.
            IN NO EVENT WILL STOCKPERKS&rsquo;S AGGREGATE LIABILITY (INCLUDING
            OUR OFFICERS, MEMBERS, DIRECTORS, EMPLOYEES, PARENTS, AND
            AFFILIATES) WITH RESPECT TO THE USE OF THE SERVICE{" "}
          </span>
          <span className="c1">
            EXCEED THE GREATER OF THE AMOUNT PAID BY YOU TO USE IN THE SIX (6)
            MONTHS PRIOR TO THE ACCRUAL OF THE CLAIM OR THE AMOUNT OF $500.00
            U.S. DOLLARS
          </span>
          <span className="c0">
            . NO ACTION, REGARDLESS OF FORM, ARISING FROM OR PERTAINING TO THE
            SERVICE OR OUR CONTENT MAY BE BROUGHT BY YOU MORE THAN ONE (1) YEAR
            AFTER SUCH ACTION HAS ACCRUED. YOU ACKNOWLEDGE THAT THESE
            LIMITATIONS OF LIABILITY WILL APPLY EVEN IF THE REMEDIES FAIL THEIR
            ESSENTIAL PURPOSE AND THAT, WITHOUT THESE LIMITATIONS, YOU WOULD
            HAVE PAID A HIGHER FEE FOR THE SERVICE AND ACCESS PROVIDED
            HEREUNDER.
          </span>
        </p>
        <p className="c21">
          <span className="c0">
            Certain states and/or jurisdictions do not allow the exclusion of
            implied warranties or limitation of liability for incidental,
            consequential or certain other types of damages, so the exclusions
            set forth above may not apply to you.
          </span>
        </p>
        <ol className="c9 lst-kix_list_5-0" start="18">
          <li className="c2">
            <span className="c7 c4">Notice</span>
          </li>
        </ol>
        <p className="c3">
          <span className="c0"></span>
        </p>
        <p className="c16">
          <span className="c1">
            Stockperks may give notice by means of a general notice via the
            Service, electronic mail to your e-mail address on record in
            Stockperks&rsquo; account information, or by written communication
            sent by first class mail or pre-paid post to your address on record
            in Stockperks&rsquo; account information. Such notice will be deemed
            to have been given upon the expiration of 48 hours after mailing or
            posting (if sent by first class mail or pre-paid post) or 12 hours
            after sending (if sent by email). You may give notice to Stockperks
            (such notice will be deemed given when received by Stockperks) at
            any time by the following: letter delivered by nationally recognized
            overnight delivery service or first class postage prepaid mail to Stockperks
          </span>
          <span className="c1 c11">
            {" "}
            500 7th Ave, New York, NY 10001
          </span>
        </p>
        <p className="c3">
          <span className="c1 c10"></span>
        </p>
        <ol className="c9 lst-kix_list_5-0" start="19">
          <li className="c2">
            <span className="c7 c4">Modification to Terms</span>
          </li>
        </ol>
        <p className="c3">
          <span className="c0"></span>
        </p>
        <p className="c5">
          <span className="c0">
            Stockperks reserves the right to modify the Terms or its policies
            relating to the Service at any time, effective upon posting of an
            updated version of the Terms on the Website or App. You are
            responsible for regularly reviewing the Terms. Continued use of the
            Service after any such changes will constitute your consent to such
            changes.
          </span>
        </p>
        <p className="c3">
          <span className="c10 c1"></span>
        </p>
        <ol className="c9 lst-kix_list_5-0" start="20">
          <li className="c2">
            <span className="c7 c4">Customer Communications; Disclosure</span>
          </li>
        </ol>
        <p className="c3">
          <span className="c0"></span>
        </p>
        <p className="c5">
          <span className="c0">
            From time-to-time Stockperks may ask whether or not you wish to
            receive marketing and other non-critical Service-related
            communications. You may elect not to receive such communications at
            that time or opt-out of receiving such communications at any
            subsequent time by notifying Stockperks. Because the Service is a
            hosted, online application, Stockperks may need to notify all users
            of the Service (whether or not they have opted out as described
            above) of important announcements regarding the operation of the
            Service.
          </span>
        </p>
        <p className="c3">
          <span className="c0"></span>
        </p>
        <ol className="c9 lst-kix_list_5-0" start="21">
          <li className="c2">
            <span className="c7 c4">
              GOVERNING LAW; ARBITRATION OF DISPUTES; NO CLASS ACTION.
            </span>
            <span className="c0">&nbsp;</span>
          </li>
        </ol>
        <p className="c3">
          <span className="c0"></span>
        </p>
        <p className="c5">
          <span className="c0">
            THIS AGREEMENT WILL BE GOVERNED BY NEW YORK LAW AND ANY DISPUTES,
            ACTIONS, CLAIMS OR CAUSES OF ACTION ARISING OUT OF OR IN CONNECTION
            WITH THIS AGREEMENT OR THE SERVICE, WITH THE EXCEPTION OF CLAIMS FOR
            INJUNCTIVE RELIEF, WILL BE RESOLVED IN ARBITRATION ADMINISTERED BY
            THE AMERICAN ARBITRATION ASSOCIATION AND LOCATED IN THE COUNTY OF
            NEW YORK, STATE OF NEW YORK. YOU MAY NOT UNDER ANY CIRCUMSTANCES
            COMMENCE OR MAINTAIN AGAINST STOCKPERKS ANY CLASS ACTION, CLASS
            ARBITRATION, OR OTHER REPRESENTATIVE ACTION OR PROCEEDING. IN THE
            EVENT THAT THIS ARBITRATION AGREEMENT IS FOR ANY REASON HELD TO BE
            UNENFORCEABLE, ANY LITIGATION AGAINST STOCKPERKS MAY BE COMMENCED
            ONLY IN THE FEDERAL OR STATE COURTS LOCATED IN COUNTY OF NEW YORK,
            STATE OF NEW YORK. YOU HEREBY IRREVOCABLY CONSENT TO THE
            JURISDICTION OF THOSE COURTS FOR SUCH PURPOSES.
          </span>
        </p>
        <p className="c3">
          <span className="c0"></span>
        </p>
        <ol className="c9 lst-kix_list_5-0" start="22">
          <li className="c2">
            <span className="c7 c4">Force Majeure</span>
          </li>
        </ol>
        <p className="c3">
          <span className="c0"></span>
        </p>
        <p className="c5">
          <span className="c0">
            Stockperks shall not be liable to you for any failure to perform any
            of its obligations (except payment obligations) under this Agreement
            during any period in which such performance is delayed by
            circumstances beyond Stockperks&rsquo; reasonable control including,
            but not limited to, fire, flood, war, embargo, strike, pandemic or
            wide-spread public health crisis, riot, unavailability of the
            Internet or the intervention of any governmental authority.
          </span>
        </p>
        <p className="c3">
          <span className="c0"></span>
        </p>
        <ol className="c9 lst-kix_list_5-0" start="23">
          <li className="c19 c15">
            <span className="c7 c4">Assignment </span>
          </li>
        </ol>
        <p className="c20">
          <span className="c7 c4"></span>
        </p>
        <p className="c5">
          <span className="c1">
            You shall not transfer, assign, sublicense nor pledge in any manner
            whatsoever, any of your rights or obligations under these Terms. We
            may transfer, assign, sublicense or pledge in any manner whatsoever,
            any of our rights and obligations under these Terms to a subsidiary,
            affiliate, or successor thereof or to any third party whatsoever,
            without notifying you or receiving your consent.
          </span>
        </p>
        <p className="c3">
          <span className="c4 c6 c24"></span>
        </p>
        <ol className="c9 lst-kix_list_5-0" start="24">
          <li className="c2">
            <span className="c7 c4">Entire Agreement; Additional Terms</span>
          </li>
        </ol>
        <p className="c3">
          <span className="c0"></span>
        </p>
        <p className="c5">
          <span className="c1">
            If you have not entered into another agreement with Stockperks
            regarding the subject matter contained in the Terms, then the Terms
            comprise the entire agreement between you and Stockperks and
            supersede all prior or contemporaneous negotiations, discussions or
            agreements, whether written or oral, between the parties regarding
            such subject matter. However, if you and Stockperks have entered
            into another written agreement regarding the subject matter set
            forth in the Terms, then the Terms should be read and interpreted in
            conjunction with such agreement and, in the event of a conflict
            between these Terms and the agreement between the parties,{" "}
          </span>
          <span className="c1">
            the written, signed agreement will govern and control
          </span>
          <span className="c0">.</span>
        </p>
        <p className="c5">
          <span className="c1 c25">
            See for yourself how Stockperks can boost collaboration and drive
            engagement across your organization.
          </span>
        </p>
        <ol className="c9 lst-kix_list_5-0" start="25">
          <li className="c2">
            <span className="c7 c4">Miscellaneous</span>
          </li>
        </ol>
        <p className="c3">
          <span className="c0"></span>
        </p>
        <p className="c5">
          <span className="c0">
            Our failure to exercise or enforce any right or provision of these
            Terms shall not constitute a waiver of such right or provision. In
            the event that any provision of these Terms is inoperative or
            unenforceable for any reason, such provision shall be enforced to
            the maximum extent permitted by law and the invalidity of such
            provision shall not affect the enforceability and validity of
            remaining provisions of the Terms. No independent contractor,
            partnership, joint venture or employer-employee relationship is
            intended or created. No action, regardless of form, which arises
            from or is related in any way whatsoever to these Terms, may be
            commenced by you more than twelve (12) months after such cause of
            action accrues.
          </span>
        </p>
        <p className="c5">
          <span className="c0">&nbsp;</span>
        </p>
        <p className="c5">
          <span className="c1">
            If you have any questions regarding these Terms or the Service,
            please contact us at hello@stockperks.com.
          </span>
        </p>
        <p className="c3">
          <span className="c0"></span>
        </p>
      </Section>
    </Layout>
  );
};

export default TermsAndConditions;
